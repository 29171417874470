import React, {useState} from 'react'
import {CardContainer, Container, Desc, Divider, Title, ToggleButton, ToggleButtonGroup, Wrapper} from './ProjectsStyle'
import ProjectCard from '../Cards/ProjectCards'


const Projects = ({openModal, setOpenModal, projects}) => {
    const [toggle, setToggle] = useState('all');
    return (
        <Container id="projects">
            <Wrapper>
                <Title>Проекты</Title>
                <Desc>
                    Я работал над широким спектром проектов. Вот некоторые из моих проектов.
                </Desc>
                <CardContainer>
                    {projects.map((project) => (
                            <ProjectCard project={project} openModal={openModal} setOpenModal={setOpenModal}/>
                        ))}
                </CardContainer>
            </Wrapper>
        </Container>
    )
}

export default Projects
