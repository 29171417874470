import React from 'react'
import {
    ButtonContainer,
    GitHubButton,
    MobileIcon,
    MobileLink,
    MobileMenu,
    Nav,
    NavbarContainer,
    NavItems,
    NavLink,
    NavLogo,
    Span,
    Symbol
} from './NavbarStyledComponent'
import {DiCssdeck} from 'react-icons/di';
import {FaBars} from 'react-icons/fa';
import {useTheme} from 'styled-components';

const Navbar = ({setDarkMode, darkMode, Bio}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const theme = useTheme()
    return (
        <Nav>
            <NavbarContainer>
                <NavLogo to='/'>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        color: theme.orderbtn,
                        marginBottom: '20;',
                        cursor: 'pointer',
                    }}>
                        <DiCssdeck onClick={() => {
                            setDarkMode(!darkMode)
                        }} size="3rem"/>
                        <Symbol>{"<"}</Symbol>
                        <Span>Fast</Span>
                        <Symbol>{"/"}</Symbol>
                        <Span>Code</Span>
                        <Symbol>{">"}</Symbol>
                    </div>
                </NavLogo>
                <MobileIcon>
                    <FaBars onClick={() => {
                        setIsOpen(!isOpen)
                    }}/>
                </MobileIcon>
                <NavItems>
                    <NavLink href="#about">Обо мне</NavLink>
                    <NavLink href='#skills'>Навыки</NavLink>
                    <NavLink href='#experience'>Опыт</NavLink>
                    <NavLink href='#projects'>Проекты</NavLink>
                </NavItems>
                <ButtonContainer>
                    <GitHubButton href={Bio.github} target="_blank">Github Profile</GitHubButton>
                </ButtonContainer>
                {
                    isOpen &&
                    <MobileMenu isOpen={isOpen}>
                        <MobileLink href="#about" onClick={() => {
                            setIsOpen(!isOpen)
                        }}>Обо мне</MobileLink>
                        <MobileLink href='#skills' onClick={() => {
                            setIsOpen(!isOpen)
                        }}>Навыки</MobileLink>
                        <MobileLink href='#experience' onClick={() => {
                            setIsOpen(!isOpen)
                        }}>Опыт</MobileLink>
                        <MobileLink href='#projects' onClick={() => {
                            setIsOpen(!isOpen)
                        }}>Проекты</MobileLink>
                        <GitHubButton style={{
                            padding: '10px 16px',
                            background: `${theme.primary}`,
                            color: 'white',
                            width: 'max-content'
                        }} href={Bio.github} target="_blank">Github Profile</GitHubButton>
                    </MobileMenu>
                }
            </NavbarContainer>
        </Nav>
    )
}

export default Navbar