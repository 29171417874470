import styled, {ThemeProvider} from "styled-components";
import {useEffect, useState} from "react";
import {darkTheme, lightTheme} from './utils/Themes.js'
import Navbar from "./components/Navbar";
import './App.css';
import {BrowserRouter as Router} from 'react-router-dom';
import HeroSection from "./components/HeroSection";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Footer from "./components/Footer";
import Experience from "./components/Experience";
import ProjectDetails from "./components/ProjectDetails";
import axios from "axios";
import {Hearts} from 'react-loader-spinner'


const Body = styled.div`
    background-color: ${({theme}) => theme.bg};
    width: 100%;
    overflow-x: hidden;
`

const Wrapper = styled.div`
    background: linear-gradient(38.73deg, rgba(204, 0, 187, 0.15) 0%, rgba(201, 32, 184, 0) 50%), linear-gradient(141.27deg, rgba(0, 70, 209, 0) 50%, rgba(0, 70, 209, 0.15) 100%);
    width: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 98%, 0 100%);
`

const WrapperCenter = styled.div`
    background: linear-gradient(38.73deg, rgba(204, 0, 187, 0.15) 0%, rgba(201, 32, 184, 0) 50%), linear-gradient(141.27deg, rgba(0, 70, 209, 0) 50%, rgba(0, 70, 209, 0.15) 100%);
    width: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 30% 98%, 0 100%);
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`

function App() {
    const url_data = "data.json"
    const [data, setData] = useState(null);
    const [darkMode, setDarkMode] = useState(true);
    const [openModal, setOpenModal] = useState({state: false, project: null});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(url_data);
                setTimeout(() => {
                    setData(response.data);
                }, 1500)
            } catch (error) {
                console.error('Ошибка при получении данных: ', error);
            }
        };
        fetchData();
    }, []);

    return (
        <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
            <Router>
                {data && <Navbar setDarkMode={setDarkMode} darkMode={darkMode} Bio={data.bio}/>}
                <Body>
                    {data ?
                        (<>
                                <Wrapper>
                                    <HeroSection Bio={data.bio}/>
                                </Wrapper>
                                <Wrapper>
                                    <Skills skills={data.skills}/>
                                    <Experience experiences={data.experiences}/>
                                </Wrapper>
                                <Projects openModal={openModal} setOpenModal={setOpenModal} projects={data.projects}/>
                                <Footer Bio={data.bio}/>
                                {openModal.state &&
                                    <ProjectDetails openModal={openModal} setOpenModal={setOpenModal}/>
                                }
                            </>
                        ) : (
                            <WrapperCenter style={{height: "100vh"}}>
                                <Hearts
                                    height="150"
                                    width="150"
                                    color="red"
                                    ariaLabel="hearts-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                            </WrapperCenter>
                        )
                    }
                </Body>
            </Router>
        </ThemeProvider>

    )

}

export default App;
